<template>
  <div>
    A venir
<!--    <div class="py-2 px-2 mb-5">-->
<!--      <v-select-->
<!--          multiple-->
<!--          v-model="filter"-->
<!--          filled-->
<!--          :items="albums"-->
<!--          label="Sélectionner des albums"-->
<!--          item-text="name"-->
<!--          item-value="name"-->
<!--      >-->
<!--      </v-select>-->
<!--    </div>-->
<!--    <v-card v-for="(album, index) in filteredAlbum" :key="index"  class="mb-4">-->
<!--      <v-card-title>{{ album.name }}</v-card-title>-->
<!--      <v-card-text>-->
<!--        <v-row>-->
<!--          <v-col-->
<!--              v-for="(photo, index) in album.photos"-->
<!--              :key="index"-->
<!--              class="d-flex child-flex"-->
<!--              cols="3"-->
<!--          >-->
<!--            <v-img-->
<!--                :src="photo.src"-->
<!--                :lazy-src="photo.lazySrc"-->
<!--                aspect-ratio="1"-->
<!--                class="grey lighten-2"-->
<!--                max-height="200px"-->
<!--            >-->
<!--              <v-btn-->
<!--                  class="ma-2"-->
<!--                  elevation="2"-->
<!--                  fab-->
<!--                  bottom-->
<!--                  left-->
<!--                  small-->
<!--                  outlined-->
<!--              >-->
<!--                <v-icon>mdi-delete</v-icon>-->
<!--              </v-btn>-->
<!--              <template v-slot:placeholder>-->
<!--                <v-row-->
<!--                    class="fill-height ma-0 primary"-->
<!--                    align="center"-->
<!--                    justify="center"-->
<!--                >-->
<!--                  <v-progress-circular-->
<!--                      indeterminate-->
<!--                      color="grey lighten-5"-->
<!--                  ></v-progress-circular>-->
<!--                </v-row>-->
<!--              </template>-->
<!--            </v-img>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-card-text>-->
<!--      <v-card-actions>-->
<!--        <v-btn class="primary">Ajouter une image <v-icon right>mdi-camera-plus</v-icon></v-btn>-->
<!--      </v-card-actions>-->
<!--    </v-card>-->
  </div>
</template>

<script>
export default {
  name: 'Albums',
  data() {
    return {
      filter: [],
      albums: [
        {
          name: 'Album 1',
          photos: [
            {
              src: 'https://cdn.pixabay.com/photo/2015/03/26/09/47/sky-690293_960_720.jpg',
              alt: 'photo 1',
            },
            {
              src: 'https://media.istockphoto.com/photos/white-fluffy-clouds-in-the-blue-sky-picture-id927456520?k=20&m=927456520&s=612x612&w=0&h=s40zji7zQGqZuZF6sww2TS2cI6Iczj_xejGOFtiUBag=',
              alt: 'photo 2',
            },
            {
              src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg',
              alt: 'photo 3',
            },
            {
              src: 'https://cdn.pixabay.com/photo/2015/01/07/15/51/woman-591576_960_720.jpg',
              alt: 'photo 4',
            },
            {
              src: 'https://cdn.pixabay.com/photo/2015/03/17/14/05/sparkler-677774_960_720.jpg',
              alt: 'photo 5',
            }
          ]
        },
        {
          name: 'Album 2',
          photos: [
            {
              src: 'https://cdn.pixabay.com/photo/2015/03/26/09/47/sky-690293_960_720.jpg',
              alt: 'photo 1',
            },
            {
              src: 'https://media.istockphoto.com/photos/white-fluffy-clouds-in-the-blue-sky-picture-id927456520?k=20&m=927456520&s=612x612&w=0&h=s40zji7zQGqZuZF6sww2TS2cI6Iczj_xejGOFtiUBag=',
              alt: 'photo 2',
            },
            {
              src: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_960_720.jpg',
              alt: 'photo 3',
            },
            {
              src: 'https://cdn.pixabay.com/photo/2015/01/07/15/51/woman-591576_960_720.jpg',
              alt: 'photo 4',
            },
            {
              src: 'https://cdn.pixabay.com/photo/2015/03/17/14/05/sparkler-677774_960_720.jpg',
              alt: 'photo 5',
            }
          ]
        }
      ]
    }
  },
  computed: {
    filteredAlbum() {
      if (this.filter.length > 0) {
        return this.albums.filter((album) => this.filter.includes(album.name))
      }
      return this.albums
    }
  }
}
</script>
